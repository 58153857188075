.notification-dialog {
  .notification-dialog-title,
  .notification-dialog-content,
  .notification-dialog-actions {
    padding-left: 40px !important;
    padding-right: 40px !important;

    &.notification-dialog-content {
      padding-bottom: 70px !important;
    }

    &.notification-dialog-actions {
      margin: 0;
    }
  }

  .notification-dialog-title {
    background-color: #edf7fe;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 20px 24px;
    align-items: center;

    span {
      color: #16324f;
      font-size: 30px;
      line-height: 30px;
    }

    button.cancel-btn {
      background-color: #fff;
      text-transform: none;
      font-size: 14px;
    }
  }

  .notification-dialog-content {
    display: flex;
    flex-direction: column;
    min-height: 300px;
    max-height: 500px;
    position: relative;
    padding-top: 18px !important;
    overflow-y: auto;
    

    .notification-content-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #16324f;

      &,
      & span {
        font-size: 14px;
        font-weight: 500;
      }

      .notification-checkbox-form-control {
        margin-right: 0;

        span {
          &.MuiTypography-root {
            text-decoration: underline;
            &:hover{
              text-decoration: none;
            }
          }
        }

        .notification-checkbox {
          padding: 0px 5px 0px 0px;
          svg {
            width: 20px;
          }
        }
      }
    }
    .notification-content-container {
      margin-top: 12px;

      .notification-content-title {
        color: #1d9bf1;
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .notification-content-body {
        color: #415262;
        font-size: 16px;
        min-height: 200px;
        font-weight: 400;
      }
    }
  }

  .notification-dialog-actions {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0 20px;
    padding-left: 0;
    padding-right: 0;

    button {
      text-transform: none;
      font-size: 14px;
      padding-left: 0;
      padding-right: 0;
      font-weight: 500;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .notification-nav {
    position: absolute;
    // bottom: 20px;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;

    button {
      border-radius: 50px;
      min-width: 0;
      padding: 0;
      margin: 0 15px;
    }
  }
}
