@import "./aligntment";
@import "./ancillary.scss";
@import "./blockHeading";
@import "./body";
@import "./client";
@import "./contact";
@import "./embed";
@import "./fontColor";
@import "./gallery";
@import "./heading";
@import "./hyperlink";
@import "./map";
@import "./nestedList";
@import "./point";
@import "./quote";
@import "./sizeTool";
@import "./sub";
@import "./subtitle";
@import "./sup";
@import "./table";
@import "./title";
@import "./inlineImage";
@import "./blockButtons.scss";
@import "./image.scss";
@import "./list.scss";

.codex-editor {
  .ce-block {
    &:hover {
      &.image {
        .ce-block__content {
          &::before {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
          }
        }
      }

      .ce-block__content {
        &::before {
          content: " ";
          width: calc(100% + 8px);
          height: calc(100% + 8px);
          position: absolute;
          top: -4px;
          left: -4px;
          border: 3px dotted rgba(29, 155, 241, 0.5);
          pointer-events: none;
        }
      }
    }

    &.ce-block--focused,
    &.ce-block--selected {
      &.image {
        .ce-block__content {
          &::before {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
          }
        }
      }

      .ce-block__content {
        // background: #e1f2ff;

        &::before {
          content: " ";
          width: calc(100% + 8px);
          height: calc(100% + 8px);
          position: absolute;
          top: -4px;
          left: -4px;
          border: 3px solid #1d9bf1;
          pointer-events: none;
        }
      }
    }

    &.video {
      .cdx-media-container,
      .cdx-media-container video {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .cdx-block {
    padding: 0;
  }

  .ce-block__content {
    max-width: 100%;
  }

  .ce-toolbar__content {
    max-width: 100%;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  .ce-popover__item {
    &[data-item-name="paragraph"] {
      display: none;
    }
  }

  .ce-toolbar {
    .ce-toolbar__plus,
    .ce-toolbar__settings-btn {
      background-color: rgba(#edf7fe, 0.8) !important;
      color: #16324f !important;
      width: 30px !important;
      height: 30px !important;
      border-radius: 8px !important;
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
      border: 1px solid #edf7fe;
      transition: all 0.3s ease;
      svg {
        transition: all 0.3s ease;
        &,
        * {
          fill: #16324f;
          stroke: #16324f;
        }
      }
      &:hover {
        background-color: rgba(#1d9bf1, 1) !important;
        border: 1px solid #1d9bf1;

        svg {
          transition: all 0.3s ease;

          &,
          * {
            fill: #ffffff;
            stroke: #ffffff;
          }
        }
      }
    }
  }

  .ce-settings {
    .ce-popover {
      overflow: scroll;

      .cdx-search-field {
        display: none;
      }
    }

    .ce-popover--opened {
      width: 520px;
      overflow: scroll;
    }

    .select-container {
      position: relative;
      margin-left: 10px;
      min-width: 120px;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
        right: 10px;
        bottom: 0;
        margin: auto;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #333 transparent transparent transparent;
        pointer-events: none;
      }
    }

    .ce-popover__item-label,
    .cdx-settings-button,
    .cdx-settings-select,
    .cdx-setting-width,
    .cdx-setting-height {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
      line-height: 1 !important;
      color: $dark-font-colour !important;
      font-size: 12px;
      font-weight: 400;
      justify-content: center;
      align-items: center;
      align-content: center;
      display: flex;

      &.cdx-settings-select,
      &.cdx-setting-width,
      &.cdx-setting-height {
        padding: 10px 5px;
        background-color: #eff2f5;
        font-size: 14px;
        border: none;
        box-shadow: none;
        border-radius: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
        outline: none;
        appearance: none;
        border: 1px solid #eff2f5;
        text-transform: capitalize;
        font-weight: 600 !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
        line-height: 1 !important;
        color: $dark-font-colour !important;
        border-radius: 3px;

        // Disable default styling on ff
        -moz-appearance: none;

        // Disable ugly ass outline on firefox
        &:-moz-focusring {
          color: transparent;
          text-shadow: 0 0 0 #000;
        }

        // Disable default styling on webkit browsers
        -webkit-appearance: none;

        // Disable default arrow on IE 11+
        &::-ms-expand {
          display: none;
        }

        &:focus {
          border-color: #eff2f5;
        }

        &.cdx-setting-width,
        &.cdx-setting-height {
          width: 70px;
        }
      }
    }

    .ce-popover__custom-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-bottom: 1px solid $border-colour;
      margin-bottom: 5px;
    }

    .ce-popover__items {
      .ce-popover__item {
        float: left;

        &[data-item-name="move-up"] {
          padding-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          margin-left: 10px;
        }

        &[data-item-name="move-down"] {
          padding-left: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &[data-item-name="move-up"],
        &[data-item-name="move-down"] {
          background-color: rgba($color: $border-colour, $alpha: 0.5);
        }

        &[data-item-name="delete"] {
          float: right;
        }
      }
    }

    .cdx-animations-settings,
    .cdx-alignment-settings,
    .cdx-color-picker-settings,
    .cdx-nested-list-settings,
    .cdx-nothing-settings,
    .cdx-list-settings,
    .cdx-table-settings,
    .cdx-dimension-settings {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      padding-bottom: 10px;
      width: 33.33%;

      &.cdx-table-settings {
        display: none;
      }

      &::before {
        display: block;
        width: 100%;
        margin: 10px 0;
        padding-left: 10px;
        font-size: 14px;
        font-weight: bold;
      }

      &.cdx-alignment-settings {
        &::before {
          content: "Alignment:";
        }
      }

      &.cdx-list-settings {
        button {
          margin-right: 5px;
        }

        &::before {
          content: "List:";
        }
      }

      &.cdx-animations-settings {
        .cdx-settings-button {
          font-weight: bold;
        }

        &::before {
          content: "Animation:";
        }
      }

      &.cdx-dimension-settings {
        width: 60%;
        
        .cdx-settings-button {
          font-weight: bold;
        }

        &::before {
          content: "Dimensions:";
        }

        .cdx-setting-width-container, .cdx-setting-height-container {
          display: flex;
          align-items: center;

          .cdx-setting-width-label,.cdx-setting-height-label {
            font-weight: 500;
            font-size: 15px;
            min-width: 60px;
            padding-left: 10px;
          }
        }
      }

      &.cdx-color-picker-settings {
        &::before {
          content: "Text colour:";
          padding-left: 5px;
        }

        .cdx-color-picker-button {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          width: 34px;
          height: 34px;
          line-height: 34px;
          padding: 0 !important;
          text-align: center;
          border-radius: 3px;
          cursor: pointer;
          border: 0;
          outline: none;
          background-color: transparent;
          vertical-align: bottom;
          color: #000;
          margin: 0;

          &.colorCircleActive {
            transition: all 0.1s ease-in-out;
            transform: scale(1.5);
          }

          .cdx-color-picker {
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            border: 2px solid #d9d9d9;
            transition: all 0.1s ease-in-out;
          }
        }
      }

      &.cdx-nothing-settings {
        margin-bottom: 0;
        padding-bottom: 0;
        font-size: 14px;
        font-weight: bold;
        border: none;

        &::before {
          content: none;
        }
      }

      &.cdx-table-settings {
        button {
          margin-right: 5px;
        }

        &::before {
          content: "Table:";
        }
      }

      .cdx-settings-button {
        flex: 0 0 auto;
        width: auto;
        text-align: center;
        text-transform: capitalize;
        padding: 2px 10px !important;
      }

      .cdx-settings-button--active {
        background-color: #eff2f5;
      }
    }
  }

  .ce-toolbox {
    .ce-popover {
      .ce-popover__item {
        .ce-popover__item-icon {
          svg {
            &,
            * {
              // fill: #16324f !important;
              // stroke: #16324f !important;
            }
          }
        }
        &:hover {
          .ce-popover__item-icon {
            svg {
              &,
              * {
                // fill: #ffffff !important;
                // stroke: #ffffff !important;
              }
            }
          }
        }
      }
    }
  }

  // buttons
  .cdx-media-container {
    position: relative;
    display: inline-block;
    min-width: 70px;
    min-height: 70px;
  }

  .cdx-media-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 0.5;
    color: #4f4f4f;
    -webkit-user-select: none;
    user-select: none;
    background: #f5f5f5;
    border: none;
    outline: none;
    border-radius: 5px;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;

    svg {
      width: 30px;
      height: 30px;
    }

    &.replace-btn {
      top: 60px;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    &:hover {
      opacity: 1;
    }
  }
}

.ce-block.list,
.list_block {
  ol,
  ul {
    li {
      &::marker {
        content: none;
      }

      &::before {
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: -25px;
      }
    }

    &.cdx-nested-list--ordered {
      counter-reset: item;

      li {
        &::before {
          content: counter(item) ".";
          counter-increment: item;
          left: -30px;
        }
      }
    }

    &.cdx-nested-list--unordered {
      li {
        &::before {
          content: "•";
        }
      }
    }
  }

  ol {
    counter-reset: item;

    li {
      &::before {
        content: counter(item) ".";
        counter-increment: item;
        left: -30px;
      }
    }
  }

  ul {
    li {
      &::before {
        content: "•";
      }
    }
  }
}

section {
  &.slide-wrap {
    // &.high-level {
    //   .ce-popover__items {
    //     display: block;
    //   }
    // }

    .ce-settings {
      .ce-popover__items {
        overflow-y: visible;
        // display: none;
      }
    }
  }

  &[data-slide-layout="table-of-contents"] {
    .tc-add-column {
      pointer-events: none;
      visibility: hidden;
    }

    .tc-add-column:hover,
    .tc-add-row:hover {
      background-color: transparent;
    }

    .tc-add-row:hover:before {
      background-color: transparent;
    }

    .tc-toolbox--column {
      display: none;
    }

    // .tc-add-row {
    //   display: none;
    // }
  }
}
