// Bootstrap customisation

// scss-docs-start color-variables
$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;

$theme-colors: (
  "primary": #685e9d,
  "secondary": #b6519a,
  "gradient-pink": #f0446a,
  "gradient-orange": #f9690e,
  "border-colour": #d9d9d9,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": #1d9bf1, //prototype
);

// Global 'pill' border radius for buttons
// $btn-border-radius: (0.375rem * 2 ) + (1rem * 1.5);
// $btn-padding-x: 1.5rem;
$btn-border-radius: 0.375rem;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";