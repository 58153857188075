.notifications.screen {
  width: 80%;
  margin: 0 auto;

  .page-header {
    margin-bottom: 0 !important;
    div.row {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .page-name {
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
      margin-right: 0;

      h1 {
        color: $ps-dark;
      }
    }
  }

  .notifications-list {

    .notification-item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-right: 0;
      margin-left: 0;
      align-items: center;
      transition: 0.25s all ease-in-out;
      border-bottom: 1px solid #d2d0cf;
      padding: 10px 0;

      .notification-item-date {
        padding-left: calc(var(--bs-gutter-x) * 0.5);
      }

      .notification-item-label {
        flex-grow: 1;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 70%;
        display: inline-block;
        text-align: left;
        max-width: 500px;
      }

      .notification-item-icon {
        width: 30px;
        height: auto;
        &.unread {
          * {
            fill: #1d9bf1;
          }
        }
      }

      span {
        font-size: 16px;
        color: $ps-body-text-color;
        font-weight: 400;
      }

      &.unread {
        svg {
          fill: $ps-brand;
        }
      }

      svg {
        fill: $ps-body-text-color;
        width: 28px;
        margin-right: 4px;
      }

      &:hover {
        cursor: pointer;
        background-color: #edf7fe;
      }
    }
  }
}
