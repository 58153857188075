// PAGE HEADER

$border-width: 1px;

.page-header {
  margin-top: $page-margin;
  margin-bottom: 0.7rem;

  .spacer {
    @media (min-width: $bp-lg) {
      padding-left: 1rem !important;
    }
  }

  .page-name {
    margin-left: 15px;
    border-bottom: 1px solid #d2d0cf;

    & > div {
      padding-bottom: 5px;
    }
    a {
      color: #000;
      input {
        cursor: pointer;
      }
    }

    h1 {
      font-size: 1.8rem;
    }

    .toggle-switch,
    h1 {
      width: revert;
      max-width: revert;
      padding-left: 0 !important;
    }

    @media (max-width: $bp-lg) {
      margin-right: 15px;
    }

    .list-header-label-container {
      padding-bottom: 7px;

      .list-header-label-column {

        &.center {
          .list-header-label {
            width: 100%;
            text-align: center;
          }
        }

        &.right {
          .list-header-label {
            width: 100%;
            text-align: right;
          }
        }

        .list-header-label {
          font-size: 18px;
          font-weight: 500;
          color: $ps-brand;
        }
      }
    }
  }

  .page-header__filters {
    display: flex;
    align-items: center;

    @media (min-width: $bp-xl) {
      font-size: 0.8em;
    }

    .category-selector {
      border-bottom: 1px solid #d2d0cf;
      width: 100%;
      z-index: 999; // ensure on highest layer

      // &>div{
      //   div:nth-child(2){
      //     span{
      //       display: none;
      //     }
      //   }
      // }
      // padding: 5px;
      // border: 1px solid $normal-font-colour;
      // border-radius: 3px;
      // color: $normal-font-colour;
      // background-color: #fff;

      & > div {
        border: none;
      }
    }

    .spacer {
      @media (min-width: $bp-sm) and (max-width: $bp-lg) {
        padding-left: 1rem !important;
      }
    }

    .filter-btn {
      @include btn-reset();
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.25rem 9px 0.25rem;
      border-bottom: $border-width solid transparent;
      width: 100%;

      span {
        color: #333;
        font-weight: 400;
      }

      svg {
        color: #4a4b4d;
        height: 18px;
        min-width: 24px;
      }

      &:nth-of-type(1) {
        border-bottom: $border-width solid #d2d0cf;
        &.active {
          & > svg {
            fill: #4a4b4d;
          }
        }
      }
    }
  }

  a {
    width: auto;
  }
}
